import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { isUserType, removeUserDetails } from "../../../utils/services/methods";
import DownArrow from "../../../components/Header/Dropdowns/DownArrow";
import { theme } from "../../../utils/global-styles/theme";

const CloseMenu = ({
  isLoggedIn,
  headerLinks,
  footerLinks,
  setMenuDropdownActive,
  isMenuDropdownActive,
  setShowScalableLogo,
  toggleHamburger,
  hamburgerMenuRef,
}) => {
  const isFellow = isUserType("Fellow");
  const isFoundry = isUserType("Foundry");

  const HamburgerMenuContainer = styled.div`
    position: absolute;
    width: 376px;
    height: 100%;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.17);
    top: -1500px;
    z-index: 6;
    right: 0;
    font-family: ${theme.fontFaces.headers};
    color: ${theme.colors.main};
    overflow-y: scroll;

    &.show-mobile-menu {
      top: 91px; /* Display when active */
      opacity: 1;
    }

    // Add a new class for showing sub-links
    &.show-sub-links {
      .my-dashboard-links-container {
        opacity: 1;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.iPadPro}) {
      width: 100vw;
      position: fixed;
      left: 0;
    }
  `;

  const MyDashboardLinksContainer = styled.div`
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
  `;

  const NavLink = styled(Link)`
    display: block;
    width: 100%;
    font-family: ${theme.fontFaces.headers};
    font-size: 19px;
    font-weight: bold;
    letter-spacing: -0.73px;
    line-height: 34px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 76.5px;
  `;

  const MyDashboardContainer = styled.div``;

  const MyDashboardHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 40px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 34px;
    letter-spacing: -0.73px;
  `;

  const Scrollable = styled.div`
    position: relative;
    overflow-y: scroll;
    width: 100%;
    height: 200vw;
    margin-bottom: 150px;
  `;

  const CloseButton = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
    color: white;
    padding: 10px;
    border: 2px solid white;
    display: ${props => (props.isMenuDropdownActive ? "flex" : "none")};
    justify-content: center;
    align-items: center;
  `;

  const CloseIconWrapper = styled.div`
    padding-left: 10px;
  `;

  const DashboardLink = styled(Link)`
    color: white;
  `;

  const [headerLinksExpanded, setHeaderLinksExpanded] = useState({});
  const [dashboardLinksExpanded, setDashboardLinksExpanded] = useState({});

  const toggleDashboardLinks = linkIndex => {
    // to be added
  };

  const headerLinksToDisplay = isLoggedIn
    ? headerLinks?.headerLinks.slice(0, -2)
    : headerLinks?.headerLinks;

  useEffect(() => {
    const menuContainer = hamburgerMenuRef.current;

    if (menuContainer) {
      if (isMenuDropdownActive) {
        menuContainer.classList.add("show-mobile-menu");
      } else {
        menuContainer.classList.remove("show-mobile-menu");
      }
    }
  }, [isMenuDropdownActive, hamburgerMenuRef]);

  useEffect(() => {
    const dropdownBtns = document.querySelectorAll(".menu-btn");
    let lastOpened = null;

    if (dropdownBtns && dropdownBtns.length > 0) {
      dropdownBtns?.forEach(btn => {
        btn?.addEventListener("click", function() {
          const menuContent = this.nextElementSibling;
          if (lastOpened !== null) {
            const target = lastOpened;

            target.addEventListener(
              "animationend",
              () => {
                target.classList.remove("show", "animate-out");

                if (target === lastOpened) {
                  lastOpened = null;
                }
              },
              {
                once: true,
              }
            );

            target.classList.add("animate-out");
          }

          if (lastOpened !== menuContent) {
            menuContent.classList.add("show");
            lastOpened = menuContent;
          }
        });
      });
    }
  });

  return (
    <HamburgerMenuContainer
      ref={hamburgerMenuRef}
      isMenuDropdownActive={isMenuDropdownActive}
      className="hamburger-menu dark-blue-bg"
    >
      <Scrollable>
        <>
          {headerLinksToDisplay?.map((link, index) => {
            return (
              <MyDashboardContainer key={index}>
                <MyDashboardHeader className="dark-blue-bg border-bottom-light menu-btn">
                  {link?.showSubLinks ? (
                    <div>{link.title}</div>
                  ) : (
                    <DashboardLink to={link?.url}>{link.title}</DashboardLink>
                  )}
                  {link?.subLinks?.length > 0 && (
                    <div>
                      <DownArrow activeDisplay={headerLinksExpanded[index]} />
                    </div>
                  )}
                </MyDashboardHeader>
                <MyDashboardLinksContainer
                  showLinks={headerLinksExpanded[index]}
                  className="my-dashboard-links-container drop_container"
                >
                  {link?.subLinks?.map((link, index) => {
                    return (
                      <NavLink
                        to={`${link.url}`}
                        key={index}
                        className="main-bg dark-blue-text border-bottom-dark-blue item"
                      >
                        {link.linkText}
                      </NavLink>
                    );
                  })}
                </MyDashboardLinksContainer>
              </MyDashboardContainer>
            );
          })}
        </>

        {isLoggedIn && (
          <ul>
            {headerLinks?.myDashboardLinks?.map((link, index) => {
              return (
                <MyDashboardContainer key={index}>
                  <MyDashboardHeader className="dark-blue-bg border-bottom-light menu-btn">
                    {link?.showSubLinks ? (
                      <div>{link.title}</div>
                    ) : (
                      <DashboardLink href={link?.url}>
                        {link.title}
                      </DashboardLink>
                    )}
                    {link?.subLinks?.length > 0 && (
                      <div onClick={() => toggleDashboardLinks(index)}>
                        <DownArrow
                          activeDisplay={dashboardLinksExpanded[index]}
                        />
                      </div>
                    )}
                  </MyDashboardHeader>
                  <MyDashboardLinksContainer
                    className="drop_container"
                    showLinks={dashboardLinksExpanded[index]}
                  >
                    {link?.subLinks?.map((link, index) => {
                      if (
                        link.linkText.toLowerCase().trim() === "fellowship" &&
                        !isFellow
                      ) {
                        return null;
                      }
                      if (
                        link.url.toLowerCase().trim() ===
                          "/dashboard?foundry" &&
                        !isFoundry
                      ) {
                        return null;
                      }
                      if (
                        link.url.toLowerCase().trim() ===
                          "/dashboard?community" &&
                        isFoundry
                      ) {
                        return null;
                      }
                      return (
                        <NavLink
                          to={`${link.url}`}
                          key={index}
                          className="main-bg dark-blue-text border-bottom-dark-blue item"
                        >
                          {link.linkText}
                        </NavLink>
                      );
                    })}
                  </MyDashboardLinksContainer>
                </MyDashboardContainer>
              );
            })}
          </ul>
        )}
      </Scrollable>
      <CloseButton
        className="dark-blue-bg"
        isMenuDropdownActive={isMenuDropdownActive}
        onClick={() => {
          setMenuDropdownActive(false);
          toggleHamburger();
        }}
      >
        Close
        <CloseIconWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.16"
            height="18.567"
            viewBox="0 0 18.16 18.567"
          >
            <g id="Arrow" transform="translate(6026.038 -911.879) rotate(90)">
              <line
                id="Line_282"
                data-name="Line 282"
                y1="13.917"
                x2="13.917"
                transform="translate(914 6010)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
              />
              <path
                id="Path_835"
                data-name="Path 835"
                d="M-11984,7321l6.586,6.575,7.133,7.145"
                transform="translate(12898.604 -1311)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
              />
            </g>
          </svg>
        </CloseIconWrapper>
      </CloseButton>
    </HamburgerMenuContainer>
  );
};

CloseMenu.propTypes = {
  isLoggedIn: PropTypes.bool,
  headerLinks: PropTypes.objectOf(
    PropTypes.shape({
      loggedInLinksCollection: PropTypes.objectOf(
        PropTypes.shape({
          items: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
        })
      ),
      loggedOutLinks: PropTypes.objectOf(
        PropTypes.shape({
          items: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
        })
      ),
      myDashboardLinksCollection: PropTypes.objectOf(
        PropTypes.shape({
          items: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
  footerLinks: PropTypes.shape({
    newsletterTitle: PropTypes.string,
    newsletterDescription: PropTypes.string,
    support: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    about: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    help: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    termsOfUse: PropTypes.objectOf(PropTypes.string),
    privacyPolicy: PropTypes.objectOf(PropTypes.string),
    facebook: PropTypes.objectOf(PropTypes.string),
    twitter: PropTypes.objectOf(PropTypes.string),
    instagram: PropTypes.objectOf(PropTypes.string),
  }),
  notificationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      datePublished: PropTypes.string,
      url: PropTypes.string,
      viewDateTimeStamp: PropTypes.string,
    })
  ),
};

export default CloseMenu;
